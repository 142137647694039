import React from "react";

const Chapter = ({ children, sub }) => {
  return (
    <div className="mb-8">
      <h1 className="text-3xl font-bold text-center mb-4">{children}</h1>
      <h2 className="text-xl mb-12 italic">{sub}</h2>
    </div>
  );
};
export default Chapter;
