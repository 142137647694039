import React from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/pictures/logo.webp";

const Header = () => {
  const navigate = useNavigate();

  return (
    <header className="bg-gradient-to-r from-amber-500 to-amber-400 text-white sticky top-0 z-50 shadow-lg">
      <div className="flex justify-between">
        <h1 className="text-xl font-semibold m-4" onClick={() => navigate("/")}>
          <img
            src={logo}
            alt="Logo"
            className="h-14 sm:h-14 md:h-14 rounded-full lg:h-16 w-auto" // Taille responsive
          />
        </h1>
        <div className="flex space-x-4 m-3">
          <button
            onClick={() => navigate("/")}
            className="bg-transparent text-white font-bold text-xl px-4 py-2 rounded transition-colors duration-150 ease-in-out hover:bg-yellow-500 hover:shadow-lg focus:outline-none"
          >
            Home
          </button>
          <button
            onClick={() => navigate("/")}
            className="bg-transparent text-white font-bold text-xl px-4 py-2 rounded transition-colors duration-150 ease-in-out hover:bg-yellow-500 hover:shadow-lg focus:outline-none"
          >
            Contact
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;
