import React from "react";

const Title2 = ({ children }) => {
  return (
    <h3 className="text-xl underline text-yellow-800 font-semibold mb-2">
      {children}
    </h3>
  );
};
export default Title2;
