import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Importez useNavigate
import background from "../../assets/pictures/background.webp";
import InputName from "./InputName";

const Accueil = () => {
  const [name, setName] = useState("");
  const navigate = useNavigate(); // Utilisez useNavigate

  // Utilisez useEffect pour détecter les changements de la variable name
  useEffect(() => {
    if (name.toLowerCase() === "mayra") {
      // Si l'utilisateur tape "Mayra" (insensible à la casse)
      navigate("/List?name=Mayra"); // Redirigez vers /Mayra
    }
  }, [name, navigate]); // Ajoutez name et navigate aux dépendances d'effet pour éviter les boucles infinies

  return (
    <div className="relative bottom-0">
      {/* Image de fond */}
      <img
        src={background}
        className="absolute inset-0 w-full h-screen object-cover"
        alt="Background"
      />

      {/* Overlay flou */}
      <div className=" absolute inset-0 h-screen backdrop-blur-sm md:backdrop-blur-md lg:backdrop-blur-lg"></div>
      <div className="flex items-center justify-center h-screen">
        <InputName />
      </div>
    </div>
  );
};

export default Accueil;
