import React from "react";
import { useLocation } from "react-router-dom";
import Chapter from "../commons/Chapter";
import Title2 from "../commons/Title2";
import Title3 from "../commons/Title3";
import Warning from "../commons/Warning";
import Remarque from "../commons/Remarque";
import Vocabulary from "../commons/Vocabulary";
import AudioPlayer from "../commons/AudioPlayer";

const Lesson = () => {
  let query = new URLSearchParams(useLocation().search);
  let number = query.get("lesson");
  let name = query.get("name");

  const data = require("../../tmp_data/" + name + ".json");
  const lesson = data["Cours " + number];
  return (
    <>
      <Chapter sub={lesson["subtitle"]}>{"Cours " + number}</Chapter>
      {Object.keys(lesson["content"]).map((key, index) => {
        let current = lesson["content"][key];
        switch (current["type"]) {
          case "title2":
            return <Title2 key={index}>{current["name"]}</Title2>;
          case "title3":
            return <Title3 key={index}>{current["name"]}</Title3>;
          case "vocabulary":
            return (
              <Vocabulary
                key={index}
                example={current["example"]}
                translation={current["translation"]}
                audio={current["audio"]}
                picture={current["picture"]}
              >
                {current["name"]}
              </Vocabulary>
            );
          case "warning":
            return (
              <Warning key={index} example={current["example"]}>
                {current["name"]}
              </Warning>
            );
          case "remarque":
            return (
              <Remarque key={index} example={current["example"]}>
                {current["name"]}
              </Remarque>
            );
          case "text":
            return <div key={index}> {current["name"]}</div>;
          case "audio":
            return <AudioPlayer key={index} src={current["src"]} />;
          default:
            return <></>;
        }
      })}
    </>
  );
};
export default Lesson;
