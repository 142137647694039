import React, { useState, useRef, useEffect } from "react";
import { FaPlayCircle, FaPauseCircle } from "react-icons/fa"; // Importez les icônes de React Icons

const AudioPlayer = ({ src }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);

  useEffect(() => {
    try {
      const sound = require("../../assets/audio/" + src);
      audioRef.current = new Audio(sound);
    } catch (error) {
      console.error("Erreur lors du chargement de l'audio :", error);
    }
  }, [src]);

  const togglePlayPause = () => {
    if (!audioRef.current) return;

    const prevState = isPlaying;
    setIsPlaying(!prevState);
    if (!prevState) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
    }
  };
  
  return (
    <div
      onClick={togglePlayPause}
      className="flex cursor-pointer whitespace-nowrap"
    >
      {isPlaying ? <FaPauseCircle size={24} /> : <FaPlayCircle size={24} />}
    </div>
  );
};

export default AudioPlayer;
