import React, { useEffect, useRef } from "react";

const Modal = ({ isOpen, onClose, children }) => {
  const modalRef = useRef();

  const handleClose = (e) => {
    if (!modalRef.current.contains(e.target)) {
      onClose();
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClose);
    }

    return () => {
      document.removeEventListener("mousedown", handleClose);
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex justify-center items-center">
      <div className="absolute inset-0 bg-black opacity-50 transition-opacity ease-out duration-300"></div>
      <div
        ref={modalRef}
        className="flex flex-col transform transition-all ease-out duration-300 scale-95 opacity-0 bg-white rounded-lg shadow-lg max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl z-50"
        style={{ animation: "fadeInZoom 0.3s forwards" }}
      >
        <div className="bg-yellow-500 flex justify-between w-full items-center rounded shadow-lg">
          <h2 className="font-bold ml-8 text-xl text-gray-100 ">Details</h2>
          <button
            onClick={onClose}
            className="float-right pr-3 pt-2 self-end font-bold mb-4  text-gray-100 "
          >
            ✕
          </button>
        </div>
        <div className="m-2">{children}</div>
      </div>
    </div>
  );
};

export default Modal;
