import React, { useEffect } from "react";
import { useState } from "react";
import AudioPlayer from "./AudioPlayer";
import ModalVocabulary from "./ModalVocabulary";

const Vocabulary = ({ children, audio, picture, translation, example }) => {
  const [open, setOpen] = useState(false);
  let picturePath = null;
  // Ajoutez une classe conditionnelle pour le décalage
  const containerClasses = `transition-transform duration-300 bg-white "z-10"
  }`;
  if (picture) {
    picturePath = require("../../assets/pictures/" + picture);
  }

  return (
    <div className={`relative`}>
      <div className={containerClasses}>
        <button
          className="hover:bg-gray-200 transition-opacity duration-300"
          onClick={() => setOpen(!open)}
        >
          <span className="font-bold">{children}</span> &rarr; {translation}
          {example && <div className="italic"> Example : {example}</div>}
        </button>
        <ModalVocabulary isOpen={open} onClose={() => setOpen(!open)}>
          <span className="font-bold">{children}</span> &rarr; {translation}
          {example && <div className="italic"> Example : {example}</div>}
          <div className=" flex justify-around items-center mt-1  ">
            {picture && (
              <a href={picturePath} target="_blank" rel="noopener noreferrer">
                <img
                  className="w-36 border-2 border-black rounded stransition-opacity duration-300"
                  src={picturePath}
                ></img>
              </a>
            )}

            <span>{audio && <AudioPlayer src={audio} />}</span>
          </div>
        </ModalVocabulary>
      </div>
    </div>
  );
};
export default Vocabulary;
