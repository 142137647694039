import "./App.css";
import Accueil from "./components/screens/Accueil";
import Header from "./components/layout/Header";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ListLessons from "./components/screens/ListLessons";
import Lesson from "./components/screens/Lesson";

function App() {
  return (
    <div className="App h-screen">
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Accueil />} />
          <Route path="/List" element={<ListLessons />} />
          <Route path="/Lesson" element={<Lesson />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
