import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const InputName = () => {
  const [name, setName] = useState("");
  const namesList = ["Mayra", "Arya", "Ashlea"];
  const [isValid, setIsValid] = useState(null);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setName(e.target.value);
  };

  const handleSubmit = () => {
    const capitalize =
      name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();

    if (namesList.includes(capitalize)) {
      navigate("/List?name=" + capitalize);
    } else {
      setIsValid(false);
    }
  };

  return (
    <div className="z-50 bg-amber-300 p-4 max-w-sm mx-auto rounded-lg shadow-2xl">
      <div className="flex flex-col items-center">
        <input
          type="text"
          placeholder="Entrez votre nom"
          value={name}
          onChange={handleChange}
          className="p-2 rounded border-0 bg-amber-100 border-yellow-300 mb-4"
        />
        <button
          onClick={handleSubmit}
          className="bg-amber-600 border-2 border-amber-800 text-white px-4 py-2 rounded hover:bg-amber-400"
        >
          Valider
        </button>
        {isValid !== null && (
          <p className={`mt-4 ${!isValid && "text-red-500"}`}>
            {!isValid && "Le nom n'est pas dans la liste"}
          </p>
        )}
      </div>
    </div>
  );
};

export default InputName;
