import { useEffect, useState, React } from "react";
import { useNavigate } from "react-router-dom"; // Importez useNavigate
import background from "../../assets/pictures/background.webp";
import { useLocation } from "react-router-dom";

const ListLessons = () => {
  const navigate = useNavigate(); // Utilisez useNavigate
  let query = new URLSearchParams(useLocation().search);
  let name = query.get("name");

  const data = require("../../tmp_data/" + name + ".json");

  return (
    <div className="relative bottom-0">
      {/* Image de fond */}
      <img
        src={background}
        className="absolute inset-0 w-full h-screen object-cover "
        alt="Background"
      />

      {/* Overlay flou */}
      <div className=" absolute inset-0 h-screen backdrop-blur-sm md:backdrop-blur-md lg:backdrop-blur-lg"></div>

      <section className=" z-50 flex flex-col items-center">
        {Object.keys(data).map((keys, index) => {
          return (
            <button
              key={index}
              className={` z-40 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 px-4 py-2 rounded-md text-white text-xl mt-4 bg-amber-400 shadow-xl hover:bg-amber-300`}
              onClick={() => {
                navigate(
                  "/Lesson/?name=" +
                    name +
                    "&lesson=" +
                    String(parseInt(index) + 1)
                );
              }}
            >
              {keys}
            </button>
          );
        })}
      </section>
    </div>
  );
};
export default ListLessons;
